import React from 'react';
import { Element } from 'react-scroll'
import { useMediaQuery } from 'react-responsive';
import DecorTop from '../../../assets/images/decor_top_left.png'
// import ApplyBg from '../../../assets/images/apply_bg.png'
import ApplyBg from '../../../assets/images/last-pic.jpg'
import { Image } from 'react-bootstrap';
import { Row } from 'react-bootstrap';

const Apply = () => {
  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)'
  });

  const withMobileClass = (className) => {
    return isNotMobile ? className : `${className} ${className+'--mobile'}`
  };

  return (
    <Row className={withMobileClass("apply-wrapper")}>
      {isNotMobile ? (
        <Element name="apply" className={withMobileClass("container")}>
          <div className="box-container">
            <div className="box">
              <h4>Get in touch with <span className="typing">Overlooked Ventures!</span></h4>
              <a
                className="button"
                target="_blank"
                rel="noreferrer"
                href="https://airtable.com/shr8whZnsTQzcfb6M"
              >
                <span>Contact Us</span>
              </a>
              <Image src={DecorTop} className="decor_top" />
            </div>
          </div>

          <div className="box-container box-container--mint">
            <div className="box">
              <h4>Have an awesome idea that has been <span className="typing overlooked">overlooked?</span></h4>
              <a
                className="mint button"
                target="_blank"
                rel="noreferrer"
                href="https://airtable.com/shrxICQtjx7CUcVyR"
              >
                <span>APPLY FOR FUNDING</span>
              </a>
              <Image src={DecorTop} className="decor_top" />
            </div>
          </div>

          <Image src={ApplyBg} className="apply-bg" />
        </Element>
      ) : (
        <Element name="apply" className={withMobileClass("container")}>
          <Image src={ApplyBg} className={withMobileClass("apply-bg")} />
          <div className={withMobileClass("box-container")}>
            <div className={withMobileClass("box")}>
              <h4>Join the journey as an investor <br />in <span className="typing">Overlooked Ventures!</span></h4>
              <a
                className="button"
                target="_blank"
                rel="noreferrer"
                href="https://airtable.com/shr8whZnsTQzcfb6M"
              >
                <span>INVEST AS AN LP</span>
              </a>
              <Image src={DecorTop} className="decor_top" />
            </div>
          </div>

          <div className={`${withMobileClass("box-container")} ${withMobileClass("box-container--mint")}`}>
            <div className="box">
              <h4>Have an awesome idea that has been <span className="typing overlooked">overlooked?</span></h4>
              <a
                className={`${withMobileClass("mint")} button`}
                target="_blank"
                rel="noreferrer"
                href="https://airtable.com/shrxICQtjx7CUcVyR"
              >
                <span>APPLY FOR FUNDING</span>
              </a>
              <Image src={DecorTop} className="decor_top" />
            </div>
          </div>

        </Element>
      )}
      
    </Row>
  );
};

export default Apply;