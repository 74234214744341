import React, { useRef, useState } from 'react';
import Slider from "react-slick";
import { useMediaQuery } from 'react-responsive';
import { Button, Image, Modal } from 'react-bootstrap';
import ArrowRight from '../../../assets/images/arrow_right_icon.svg';
import NextIcon from '../../../assets/images/next_icon.svg';
import NextSliderIcon from '../../../assets/images/next_slider_icon.svg';
import PrevIcon from '../../../assets/images/prev_icon.svg';
import PrevSliderIcon from '../../../assets/images/prev_slider_icon.svg';
import LinkIcon from '../../../assets/images/link_icon.svg';
import XIcon from '../../../assets/images/x_icon.svg';
import data from '../data/slider';

const SliderContent = () => {
  const [sliding, setSliding] = useState(false);
  const [currentSlider, setCurrentSlider] = useState(0);
  const [currentModal, setCurrentModal] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const ref = useRef({});

  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)'
  });

  const withMobileClass = (className) => {
    return isNotMobile ? className : `${className} ${className+'--mobile'}`
  };

  const next = () => {
    setSliding(true)
    ref.current.slickNext();
    setCurrentSlider(currentSlider + 1)
  };

  const prev = () => {
    setSliding(true)
    ref.current.slickPrev();
    setCurrentSlider(currentSlider - 1)
  };

  const settings = {
    infinite: true,
    speed: 500,
    variableWidth: true,
    slidesToScroll: 1,
    setCurrentSlider: (current) => setCurrentSlider(current),
    afterChange: () => setSliding(false),
  };

  return (
    <div className="slider-wrapper">
      <div className={withMobileClass("container")}>
      <h4 className="slider-title"><span>Janine’s </span>Angel Investments</h4>
        <div className="slider-button d-flex justify-content-end">
          <Button
            className={`next-icon ${currentSlider === 0 ? 'disable' : ''}`}
            disabled={currentSlider === 0 || sliding}
            onClick={() => prev()}
          >
            <Image src={PrevSliderIcon} />
          </Button>
          <Button
            className={`next-icon ${currentSlider === 8 ? 'disable' : ''}`}
            onClick={() => next()}
            disabled={currentSlider === 8 || sliding}
          >
            <Image src={NextSliderIcon} />
          </Button>
        </div>
        <div className="slider-content">
          <Slider ref={ref} {...settings}>
            { data && data.map((item) => (
              <div className="slider-item" key={item.id}>
                <Image src={item.logo} width={225} height={92} />
                <div className="slider-item__content">
                  <h6>{item.name}</h6>
                  <p className="small-text">{item.kind}</p>
                </div>
                <Button
                  className="slider-item__show d-flex justify-content-center align-items-center"
                  onClick={() => {
                    setCurrentModal(item.id - 1);
                    setShowModal(true);
                  }}
                >
                  <Image src={ArrowRight} />
                </Button>
              </div>
            ))}
          </Slider>
          <div className="d-flex d-none justify-content-end slider-button-black mt-4">
            <Button
              className={`next-icon ${currentSlider === 0 ? 'disable' : ''}`}
              disabled={currentSlider === 0 || sliding}
              onClick={() => prev()}
            >
              <Image src={PrevSliderIcon} />
            </Button>
            <Button
              className={`next-icon ${currentSlider === 8 ? 'disable' : ''}`}
              onClick={() => next()}
              disabled={currentSlider === 8 || sliding}
            >
              <Image src={NextSliderIcon} />
            </Button>
          </div>
        </div>
      </div>

      <Modal
        size="xl"
        className={withMobileClass("modal-full")}
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Body>
          <Button
            className={withMobileClass("x-button")}
            onClick={() => setShowModal(false)}
          >
            <Image src={XIcon} />
          </Button>
          <Image src={data[currentModal].bg} fluid className='mobile-modal'/>
          <div className={withMobileClass("slider-content")}>
            <div className={withMobileClass("slider-content__image investment-slider-image")}>
              <Image src={isNotMobile ? data[currentModal].logoSquare : data[currentModal].logo} />
            </div>
            <div className={withMobileClass("slider-content__content")}>
              <h5 className="text-uppercase">{data[currentModal].name}</h5>
              <a
                href={`${data[currentModal].url}`}
                target="_blank"
                rel="noreferrer"
              >
                {data[currentModal].url}
                <Image src={LinkIcon} />
              </a>
              <p>{data[currentModal].kind}</p>
              <p className="content">
                {data[currentModal].description}
              </p>
              <p className="small-text">
                {data[currentModal].founder}
              </p>
            </div>
          </div>
          <div className={withMobileClass("slider-footer")}>
            <div className="prev">
              <Button
                className={`${currentModal === 0 ? 'disable' : ''}`}
                disabled={currentModal === 0}
                onClick={() => setCurrentModal(currentModal - 1)}
              >
                <Image src={PrevSliderIcon} />
              </Button>
              <span className="prev__text">PREV</span>
            </div>
            <div className="prev">
              <span className="next__text">NEXT</span>
              <Button
                className={`${currentModal === 8 ? 'disable' : ''}`}
                disabled={currentModal === 8}
                onClick={() => setCurrentModal(currentModal + 1)}
              >
                <Image src={NextSliderIcon} />
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SliderContent;
