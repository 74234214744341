import React from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { Button, Element } from 'react-scroll'
import PlayIcon from '../../../assets/images/play_icon.png';

const Philosophy = () => {
  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)'
  });

  const withMobileClass = (className) => {
    return isNotMobile ? className : `${className} ${className+'--mobile'}`
  };

  return (
    <div className={withMobileClass('philosophy-wrapper')}>
      <Element name="philosophy" className={withMobileClass('container')}>
        <Row className="philosophy-content" id="philosophy-content">
          <Col md={6} xs={12} className="left-side header-item">
            <div className="line-container">
              <div className={withMobileClass('line')} />
            </div>
            <div>
              <div className="upperline">OUR PHILOSOPHY</div>
              <h2 className={isNotMobile ? '' : 'h2_mobile'}>Opportunities</h2>
              <h2 className={isNotMobile ? '' : 'h2_mobile'}>are never out</h2>
              <h2 className={isNotMobile ? '' : 'h2_mobile'}>of <span className="typing">reach</span></h2>
            </div>
          </Col>
          <Col md={6} xs={12} className="right-side header-item d-flex  align-items-center">
            <div className="philosophy-detail">
              <p>
                We support founders
                <span className="bold">
                  &#8201;who operate early-stage technology companies who are historically overlooked&#8201;
                </span>
                and provide them capital, resources, and connections to scale their business.
              </p>
              <p>
                We’ve been in your shoes. Our general partner is a tech founder with 10+ years of experience running companies and making deals. The team is full of entrepreneurial-minded marketers and leaders, and now we’re authentically supporting entrepreneurs with capital and a founder-friendly focus. 
              </p>
              <a
                href="https://vimeo.com/551488832"
                target="_blank"
                rel="noreferrer"
                className="video-button d-none"
              >
                <Image src={PlayIcon} />
                OUR VIDEO
              </a>
            </div>
          </Col>
        </Row>
      </Element>
    </div>
  );
};

export default Philosophy;
