import React from 'react';
import { Image } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { Element } from 'react-scroll'
import ArlanCapital from '../../../assets/images/arlancapital.png';
import FundStory from '../../../assets/images/fundstory.png';
import BlackVentures from '../../../assets/images/blackventures.png';
import ParentPreneur from '../../../assets/images/parentpreneur.png';
import CobbleLogo from '../../../assets/images/cobble-partner.svg'
import Vitalize from '../../../assets/images/vitalize.png';
import Brex from '../../../assets/images/Brex.png';
import { Row } from 'react-bootstrap';


const Partners = () => {
  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)'
  });

  const withMobileClass = (className) => {
    return isNotMobile ? className : `${className} ${className+'--mobile'}`
  };

  return (
    <Row className={withMobileClass('partners-wrapper')}>
      <Element name="philosophy" className={withMobileClass('container')}>
        <div className={withMobileClass('partners-content')}>
          <h4>Our <span>Partners</span></h4>
          <p>Overlooked has teamed up with fellow VCs, angel syndicates, alternative sources of capital, and other organizations to provide founders with more value.</p>
          <div className='d-flex  align-items-start logo-container flex-wrap justify-content-center'>
            <a href="https://arlan.capital/" target="_blank" rel="noreferrer">
              <Image src={ArlanCapital} />
            </a>
            <a href="https://www.fundstory.com/overlooked-ventures" target="_blank" rel="noreferrer">
              <Image src={FundStory} />
            </a>
            <a href="https://www.blackgirlventures.org/" target="_blank" rel="noreferrer">
              <Image src={BlackVentures} />
            </a>
            <a href="https://www.brex.com/product/?partnerId=overlooked" target="_blank" rel="noreferrer">
              <Image src={Brex} />
            </a>
            <a href="https://www.gocobble.com/" target="_blank" rel="noreferrer" className='cobble-partner'>
              <Image src={CobbleLogo} />
            </a>
            <a href="https://www.parentpreneurfoundation.org/" target="_blank" rel="noreferrer">
              <Image src={ParentPreneur} />
            </a>
          </div>
        </div>
      </Element>
    </Row>
  );
};

export default Partners;
