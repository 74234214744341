import Juan from '../../../assets/images/Juan.png';
import Rhian from '../../../assets/images/rhian.jpeg';
import RhianZoom from '../../../assets/images/rhian_zoom.jpeg';
import Demetrius from '../../../assets/images/Demetrius.png';
import Megan from '../../../assets/images/Megan.png';
// import Nima from '../../../assets/images/Nima.png';
import Nima from '../../../assets/images/Nima3.png';
import Bea from '../../../assets/images/Bea.png';
import Arion from '../../../assets/images/Arion.png';
import Naza from '../../../assets/images/Naza.png';
import Lalo from '../../../assets/images/lalo.png';
// import Govalo from '../../../assets/images/govalo.png';
import Govalo from '../../../assets/images/govalo3.png';
// import CollegeCash from '../../../assets/images/collegecash.svg';
import CollegeCash from '../../../assets/images/collegecash.png';
// import MomentAi from '../../../assets/images/momentai.png';
import MomentAi from '../../../assets/images/momentai.svg';
import BloomingHealth from '../../../assets/images/bloominghealth.png';
import TheDifference from '../../../assets/images/thedifference.png';
// import Femly from '../../../assets/images/femly.png';
import Femly from '../../../assets/images/femly2.png';
import CapreDm from '../../../assets/images/CapreDm.png';
import PipeLogoSquare from '../../../assets/images/pipe_logo_square.png';
import PipeBg from '../../../assets/images/pipe_bg.png';
import StaggerLogoSquare from '../../../assets/images/stagger_logo_square.png';
import StaggerBg from '../../../assets/images/stagger_bg.png';
import WestTenthLogoSquare from '../../../assets/images/west_tenth_logo_square.png';
import WestTenthBg from '../../../assets/images/west_tenth_bg.png';
import PrometeoLogoSquare from '../../../assets/images/prometeo_logo_square.png';
import PrometeoBg from '../../../assets/images/prometeo_bg.png';
import CoupletCoffeeLogoSquare from '../../../assets/images/couplet_coffee_logo_square.png';
import CoupletCoffeeBg from '../../../assets/images/couplet_coffee_bg.png';
import InfiniteObjectsLogoSquare from '../../../assets/images/infinite_objects_logo_square.png';
import InfiniteObjectsBg from '../../../assets/images/infinite_objects_bg.png';
import AkashSystemsLogoSquare from '../../../assets/images/akash_systems_logo_square.png';
import AkashSystemsBg from '../../../assets/images/akash_systems_bg.png';

const Data = [
  {
    id: 1,
    profilePicture: Juan,
    logoSquare: Lalo,
    bg: PipeBg,
    name: 'Juan Medina',
    kind: 'LALO',
    url: 'https://www.lalo.app',
    className: 'lalo-icon',
    founderDesc: 'Juan is a Latinx immigrant from South America (Colombia/Bolivia) created Lalo to honor his late father who passed away in 2003 to Multiple Sclerosis. He currently resides in Seattle, Washington with his family.',
    companyDesc: 'Lalo is an app for friends and families to celebrate and preserve a loved one\'s life stories, legacy, and traditions.',
  },
  {
    id: 2,
    profilePicture: RhianZoom,
    modalPicture: Rhian,
    logoSquare: Govalo,
    bg: StaggerBg,
    name: 'Rhian Beutler',
    kind: 'GOVALO',
    url: "https://govalo.com",
    className: 'gvalo-icon',
    founderDesc: 'Rhian founded Govalo after having a conversation about the future of gifting in e-commerce. Before founding Govalo, Rhian started venntov to help Shopify merchants manage SEO, order lookups nad time tracking.',
    companyDesc: 'Govalo reinvents the digital gifting experience for Shopify businesses.',
  },
  {
    id: 3,
    profilePicture: Demetrius,
    logoSquare: CollegeCash,
    bg: WestTenthBg,
    name: 'Demetrius Curry',
    kind: 'COLLEGE CASH',
    url: 'https://yourcollegecash.com',
    className: 'collegecash-icon',
    founderDesc: 'Demetrius Curry is a United States Marine Corps Veteran with a background in benefits management and marketing after realizing the negative effect that debt has on so many recent grads',
    companyDesc: 'College Cash changes the way debt is paid using everyday behavior.',
  },
  {
    id: 4,
    profilePicture: Megan,
    logoSquare: MomentAi,
    bg: PrometeoBg,
    name: 'Megan Gray',
    kind: 'MOMENT.AI',
    url: 'https://moment.ai',
    className: '',
    founderDesc: 'Megan Gray is a Black African-American founder with a disability who founded moment.ai after having 10 seizures on a flight home and being told she would never drive again.',
    companyDesc: 'moment.ai is deep tech/mobility tech company that analyzes driver facial biometrics to identify health risks and prevent accidents.',
  },
  {
    id: 5,
    profilePicture: Nima,
    logoSquare: BloomingHealth,
    bg: CoupletCoffeeBg,
    name: 'Nima Roohi',
    kind: 'BLOOMING HEALTH',
    url: 'https://gobloominghealth.com',
    className: 'blooming-icon',
    founderDesc: 'Nima Roohi was born in Iran and emigrated to the United States at 22 and became interested in Social Determinants of Health space in 2018. After learning about its impacts on cost savings and quality of life, he started Blooming Health to create solutions for empowering older adults to age in the comfort of their own homes. ',
    companyDesc: 'Blooming health is a one-stop tech-enabled operating system for aging in place.',
  },
  {
    id: 6,
    profilePicture: Bea,
    logoSquare: TheDifference,
    bg: InfiniteObjectsBg,
    name: 'Bea Arthur, LMHC',
    kind: 'THE DIFFERENCE',
    url: 'https://thedifference.co',
    className: 'thedifference-icon',
    founderDesc: 'Bea Arthur is a Columbia University-trained psychotherapist who founded The Difference after many years of working with high-performance individuals in high-pressure work environments. ',
    companyDesc: 'The Difference is a modern mental healthcare agency providing on-demand therapy for companies and communities.',
  },
  {
    id: 7,
    profilePicture: Arion,
    logoSquare: Femly,
    bg: AkashSystemsBg,
    name: 'Arion Long',
    kind: 'FEMLY',
    url: 'https://femlybox.com',
    className: '',
    founderDesc: 'After experiencing a cancer-scare and learning that 80% of women lack feminine care access at some point in their lives, Arion launched Femly. Femly was created to provide overachieving products, education, and increase access to help end-period poverty and menstrual inequality.',
    companyDesc: 'Femly is a hi-tech, plant-based, feminine care company that increases access to healthier personal care alternatives.',
  },
  {
    id: 8,
    profilePicture: Naza,
    logoSquare: CapreDm,
    bg: PipeBg,
    name: 'Naza Shelley & Sali Hama',
    kind: 'CARPE DM',
    url: 'https://carpedmdating.com',
    className: 'carpe-icon',
    founderDesc: 'Naza Shelley and Sali Hama met as students at Howard University School of Law and have since teamed up to start CarpeDM. Naza Shelley, CEO of CarpeDM faced the struggles of dating as a professional Black Woman in DC and knew there was a gap in the matchmaking and dating app market. So she created CarpeDM, a membership-only matchmaking service and dating app for professional Black Women.',
    companyDesc: 'CarpeDM is a tech-enabled personal matchmaking service and patented dating app uniquely designed to help professional Black women find love.',
  }
]

export default Data;
