import React from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import { Element } from 'react-scroll'
import { useMediaQuery } from 'react-responsive';
import Intersection from '../../../assets/images/intersection.svg';
import LinkCrossed from '../../../assets/images/link-crossed.svg';
import XCircle from '../../../assets/images/x-circle.svg';
import ScaleAttorney from '../../../assets/images/scale-attorney.svg';
import UserId from '../../../assets/images/user-id.svg';

const WhoWeAre = () => {
  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)'
  });

  const withMobileClass = (className) => {
    return isNotMobile ? className : `${className} ${className+'--mobile'}`
  };

  return (
    <>
    {isNotMobile ? (
      <div id="who-we-are" className={withMobileClass("partner-header-wrapper")} style={{background: '#0C201A', height: '60rem'}}>
        <Element className={withMobileClass("container")} name="team">
          <>
          <Row style={{height:'unset'}}>
            <Col sm="6" className="container-right">
              <div className="line-container">
                <div className="line" />
              </div>
              <div className="stats-content">
                <div className="stats-block" style={{textAlign: 'left'}}>
                  <div className="upperline" style={{marginLeft: 0}}>OUR MISSION</div>
                  <h2 style={{color: '#E9C49A'}} className={isNotMobile ? '' : 'h2_mobile'}><span className={`${isNotMobile ? '' : 'h2_mobile'} typing`}>What is </span>Overlooked?</h2>
                </div>
              </div>
            </Col>
            <Col sm="6" className="container-left" style={{letterSpacing: '1px'}}>
              <div className="box-text">
                <p>
                Overlooked Ventures is focusing our efforts on creating opportunities for groups who continually question whether they have adequate representation to make equitable programmatic, hiring, governance, financial, and other decisions. These individuals may have multiple identities and experiences.
                </p>
              </div>
            </Col>
          </Row>
          <Row style={{height:'unset', marginTop: '120px', paddingLeft: '2rem'}} className="person-section">
            <Col md="4">
              <Image src={LinkCrossed} className="person-img" style={{width: '35px'}}/>
              <div className='content' style={{color: '#fff', marginTop: '18px', letterSpacing: '1px', marginBottom: '90px', paddingRight: '80px'}}>
                For us, an overlooked founder has been impacted by oppression on an individual, institutional, and structural level.
              </div>
            </Col>
            <Col md="4">
              <Image src={UserId} className="person-img" style={{width: '35px'}}/>
              <div className='content' style={{color: '#fff', marginTop: '18px', letterSpacing: '1px', marginBottom: '90px', paddingRight: '80px'}}>
                We will not overlook all the dimensions that have formed your identity, experience, and opportunities.
              </div>
            </Col>
            <Col md="4">
              <Image src={XCircle} className="person-img" style={{width: '35px'}}/>
              <div className='content' style={{color: '#fff', marginTop: '18px', letterSpacing: '1px', marginBottom: '90px', paddingRight: '80px'}}>
                We will not overlook the effects of white supremacy and our role in dismantling it.
              </div>
            </Col>
            <Col md="4">
              <Image src={ScaleAttorney} className="person-img" style={{width: '35px'}}/>
              <div className='content' style={{color: '#fff', marginTop: '18px', letterSpacing: '1px', marginBottom: '90px', paddingRight: '80px'}}>
                We will not overlook equity for equality.
              </div>
            </Col>
            <Col md="4">
              <Image src={Intersection} className="person-img" style={{width: '35px'}}/>
              <div className='content' style={{color: '#fff', marginTop: '18px', letterSpacing: '1px', marginBottom: '90px', paddingRight: '80px'}}>
                We will not overlook the compounding effects of intersectionality.
              </div>
            </Col>
          </Row>
          </>
      </Element>
    </div>
    ) : (
      <>
        <Row>
        </Row>
      </>
    )}
    </>
  );
};

export default WhoWeAre;