import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-scroll'
import { useMediaQuery } from 'react-responsive';
import LogoLight from '../../../assets/images/logo_light.png'
import InstagramIcon from '../../../assets/images/instagram_icon.svg'
import TwitterIcon from '../../../assets/images/twitter_icon.svg'
import EmailIcon from '../../../assets/images/email_icon.svg'

const Footer = () => {
  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)'
  });

  const withMobileClass = (className) => {
    return isNotMobile ? className : `${className} ${className+'--mobile'}`
  };
  return (
    <div className={withMobileClass("footer-wrapper")}>
      <div className={withMobileClass("container")}>
        <div className="d-flex justify-content-center">
          <Image className={withMobileClass("logo")} src={LogoLight} />
        </div>
        <div className={withMobileClass("menu")}>
          <Link
            to="philosophy"
            spy={true}
            smooth={true}
            duration={500}
            className={withMobileClass("item")}
          >
            PHILOSOPHY
          </Link>
          <Link
            to="thesis-record"
            spy={true}
            smooth={true}
            duration={500}
            className={withMobileClass("item")}
          >
            THESIS
          </Link>
          <Link
            to="who-we-are"
            spy={true}
            smooth={true}
            duration={500}
            className={withMobileClass("item")}
          >
            MISSION
          </Link>
          <Link
            to="track_report"
            spy={true}
            smooth={true}
            duration={500}
            className={withMobileClass("item")}
          >
            TRACK RECORD
          </Link>
          <Link
            to="team"
            spy={true}
            smooth={true}
            duration={500}
            className={withMobileClass("item")}
          >
            TEAM
          </Link>
          <Link
            to="process_content"
            spy={true}
            smooth={true}
            duration={500}
            className={withMobileClass("item")}
          >
            PROCESS
          </Link>
          <Link
            to="apply"
            spy={true}
            smooth={true}
            duration={500}
            className={withMobileClass("button")}
          >
            <span>APPLY</span>
          </Link>
        </div>
        <div className={withMobileClass("coppy-right")}>
          <div className={withMobileClass("text")}>
            © 2022 Overlooked Ventures. <span>All rights reserved</span>
          </div>
          <div className="social">
            <a
              href="https://twitter.com/OverlookedVC"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={TwitterIcon} />
            </a>
            <a
              href="https://instagram.com/OverlookedVC"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={InstagramIcon} />
            </a>
            <a
              href="mailto:hello@overlookedventures.com"
            >
              <Image src={EmailIcon} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;