import React, { useRef, useState } from 'react';
// import Slider from "react-slick";
import { useMediaQuery } from 'react-responsive';
import { Button, Image, Modal, Row, Col } from 'react-bootstrap';
import ArrowRight from '../../../assets/images/arrow_right_icon.svg';
// import NextIcon from '../../../assets/images/next_icon.svg';
import NextSliderIcon from '../../../assets/images/next_slider_icon.svg';
// import PrevIcon from '../../../assets/images/prev_icon.svg';
import PrevSliderIcon from '../../../assets/images/prev_fund_slider_icon.svg';
import LinkIcon from '../../../assets/images/link_icon.svg';
import XIcon from '../../../assets/images/x_icon.svg';
import data from '../data/fundSlider';
import JM from '../../../assets/images/janine_mi.jpg';

const SliderContent = () => {
  // const [sliding, setSliding] = useState(false);
  const [currentSlider, setCurrentSlider] = useState(0);
  const [currentModal, setCurrentModal] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const fundRef = useRef({});

  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)'
  });

  const withMobileClass = (className) => {
    return isNotMobile ? className : `${className} ${className+'--mobile'}`
  };

  // const next = () => {
  //   setSliding(true)
  //   fundRef.current.slickNext();
  //   setCurrentSlider(currentSlider + 1)
  // };

  // const prev = () => {
  //   setSliding(true)
  //   fundRef.current.slickPrev();
  //   setCurrentSlider(currentSlider - 1)
  // };

  // const settings = {
  //   infinite: true,
  //   speed: 500,
  //   variableWidth: true,
  //   slidesToScroll: 1,
  //   setCurrentSlider: (current) => setCurrentSlider(current),
  //   afterChange: () => setSliding(false),
  // };

  return (
    <div className="fund-slider-wrapper">
      <div className={withMobileClass("container")}>
        <span className='fund-1'><span  className="slider-title">Fund I  </span><span> Investments</span></span>
        {/* <div className="slider-button d-flex justify-content-end">
          <Button
            className={`next-icon ${currentSlider === 0 ? 'disable' : ''}`}
            disabled={currentSlider === 0 || sliding}
            onClick={() => prev()}
          >
            <Image src={PrevIcon} />
          </Button>
          <Button
            className={`next-icon ${currentSlider === 7 ? 'disable' : ''}`}
            onClick={() => next()}
            disabled={currentSlider === 7 || sliding}
          >
            <Image src={NextIcon} />
          </Button>
        </div> */}
        {/* <div className="slider-content">
          <Slider ref={fundRef} {...settings}>
            { data && data.map((item) => (
              <div className="slider-item" key={item.id}>
                <Image src={item.profilePicture} width={300} height={400} />

                <div className="slider-item__content">
                  <h6>{item.name}</h6>
                  <p className="small-text">{item.kind}</p>
                </div>
                <Button
                  className="slider-item__show d-flex justify-content-center align-items-center"
                  onClick={() => {
                    setCurrentModal(item.id - 1);
                    setShowModal(true);
                  }}
                >
                  <Image src={ArrowRight} />
                </Button>
              </div>
            ))}
          </Slider>
        </div> */}
        <Row className="slider-content">
            {data && data.map((item) => (
              <Col md="3" className="slider-item" key={item.id}>
                <Image className='slider-img' src={item.profilePicture} height={400}/>

                <div className="slider-item__content">
                  <h6>{item.name}</h6>
                  <p className="small-text">{item.kind}</p>
                </div>
                <Button
                  className="slider-item__show d-flex justify-content-center align-items-center"
                  onClick={() => {
                    setCurrentModal(item.id - 1);
                    setShowModal(true);
                  }}
                >
                  <Image src={ArrowRight} />
                </Button>
              </Col>
            ))}
        </Row>
        <Row className='company-intro d-none'>
          <Col md="6">
            <Row>
              <Col md="12" className='left'>
                <div className='head'>About</div>
                <div className='desc'>Fund I is an industry-agnostic, pre-seed/seed stage fund investing in tech and tech-enabled startups founded by one or more historically-ignored founders. </div>
              </Col>
              <Col md="12" className='right'>
                <div className='head'>Fund I General Partners</div>
                <div className='desc'>Janine Sickmeyer & Brandon Brooks founded Overlooked Ventures in May 2021 and served as Co-GPs for Fund I. </div>
                <div className='desc mt-3'>As entrepreneurs, investors, and operators, Janine and Brandon are recognized leaders in the startup and investment spaces. Janine is a founder and CEO of a legal tech SaaS company that she bootstrapped to acquisition in 6 years. Brandon helps connect venture capitalists and alternative forms of funding to help under-invested. Both have been featured in major publications such as Forbes and Business Insider.</div>
              </Col>
            </Row>
          </Col>
          <Col md="6">
            <Image src={JM} style={{width:"100%", height:"100%", objectFit:"cover"}}/>
          </Col>
        </Row>
      </div>
      <Modal
        size="xl"
        className={withMobileClass("modal-full")}
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Body>
          <Button
            className={withMobileClass("x-button")}
            onClick={() => setShowModal(false)}
          >
            <Image src={XIcon} />
          </Button>
          <div className={`d-flex ${withMobileClass("modile-modal-open-modal")}`}>
            <Image src={data[currentModal].modalPicture ? data[currentModal].modalPicture : data[currentModal].profilePicture} style={{ objectFit: 'cover' }} width="50%" className={withMobileClass("modal-mobile-img")}/>
            <div className={withMobileClass("slider-content")}>
              <div className={withMobileClass("slider-content__content")}>
                <h5 className="text-uppercase">{data[currentModal].name}</h5>
                <p className="content">
                  {data[currentModal].founderDesc}
                </p>
                <div className={withMobileClass("slider-content__image")}>
                  <Image src={data[currentModal].logoSquare} className={data[currentModal].className}/>
                </div>
                <a
                  href={`${data[currentModal].url}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {data[currentModal].url.split('/')[2]}
                  <Image src={LinkIcon} className="arrow-top" />
                </a>
                <p className="small-text">
                  {data[currentModal].companyDesc}
                </p>
              </div>
            </div>
          </div>
          <div className="fund-prev">
            <Button
              className={`${currentModal === 0 ? 'disable' : ''}`}
              disabled={currentModal === 0}
              onClick={() => setCurrentModal(currentModal - 1)}
            >
              <Image src={PrevSliderIcon} />
            </Button>
            <span className="prev__text">PREV</span>
          </div>
          <div className="fund-next">
            <span className="next__text">NEXT</span>
            <Button
              className={`${currentModal === 7 ? 'disable' : ''}`}
              disabled={currentModal === 7}
              onClick={() => setCurrentModal(currentModal + 1)}
            >
              <Image src={NextSliderIcon} />
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SliderContent;
