import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useMediaQuery } from 'react-responsive';
import 'react-circular-progressbar/dist/styles.css';

const Diveser = () => {
  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)'
  });

  const withMobileClass = (className) => {
    return isNotMobile ? className : `${className} ${className+'--mobile'}`
  };

  return (
    <div className={withMobileClass("diverse-wrapper")}>
      <div className={withMobileClass("container")}>
        <div className={withMobileClass("container-block")}>
          <h4>Overlooked founders fund overlooked founders</h4>
          <div className={withMobileClass("chart-group")}>
            <div className="chart-item">
              <CircularProgressbar
                value={28}
                strokeWidth={5}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  pathColor: '#b57e81',
                  trailColor: '#20332d',
                })}
              />
              <div className="chart-item-content">
                <div className="chart-item-content__box">
                  <p className="small-text">only</p>
                  <h4>28%</h4>
                  <p className="small-text">of decision makers in VC are</p>
                  <p className="small-text">not white men</p>
                </div>
              </div>
            </div>

            <div className="chart-item">
              <CircularProgressbar
                value={4.9}
                strokeWidth={5}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  pathColor: '#95a49f',
                  trailColor: '#20332d',
                })}
              />
              <div className="chart-item-content">
                <div className="chart-item-content__box">
                  <p className="small-text">only</p>
                  <h4>4.9%</h4>
                  <p className="small-text">of VC partners in the U.S.</p>
                  <p className="small-text">are women</p>
                </div>
              </div>
            </div>

            <div className="chart-item">
              <CircularProgressbar
                value={2.1}
                strokeWidth={5}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  pathColor: '#657c8d',
                  trailColor: '#20332d',
                })}
              />
              <div className="chart-item-content">
                <div className="chart-item-content__box">
                  <p className="small-text">only</p>
                  <h4>2.1%</h4>
                  <p className="small-text">of firms are founded by</p>
                  <p className="small-text">women of color</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Diveser;