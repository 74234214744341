import React from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { Element } from 'react-scroll'
import DecorTop from '../../../assets/images/dark_decor_top_left.png'
import DecorBottom from '../../../assets/images/dark_decor_bot_right.png'
import Janine from '../../../assets/images/janine-process.jpg'


const Process = () => {
  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)'
  });

  const withMobileClass = (className) => {
    return isNotMobile ? className : `${className} ${className+'--mobile'}`
  };

  return (
    <div className={withMobileClass('process-wrapper')}>
      <Element name="philosophy" className={withMobileClass('container')}>
        <Row className="philosophy-content d-flex flex-wrap">
          <Col sm={6} md={6} xs={12} className="left-side header-item">
            <div className="line-container">
              <div className={withMobileClass('line')} />
            </div>
            <div>
              <div className="upperline">OUR <span>PROCESS</span></div>
              <h2 className={isNotMobile ? '' : 'h2_mobile'}>We put <span>transparency</span></h2>
              <h2 className={isNotMobile ? '' : 'h2_mobile'}>at the forefront</h2>
              <p>Here's how Overlooked Ventures uses an<br/>
                <span>unbiased approach</span> to manage incoming deal flow.
              </p>
              <div style={{position: 'relative'}}>
                <Image src={Janine} style={{width: '36rem'}}/>
              </div>
            </div>
          </Col>
          <Col sm={6} md={6} xs={12} className="right-side header-item d-flex  align-items-center">
            <div className="process-detail" name="process_content">
              <Image src={DecorTop} className="decor_top" style={{ position: 'absolute' }} />
              <div className="process-content">
                <div className="box">
                  <h4 className="number-box">1</h4>
                  <h6>Apply</h6>
                  <p>Founder submits an interest form via <span className='bold'>Airtable</span>.</p>
                </div>
                <div className="box">
                  <h4 className="number-box">2</h4>
                  <h6>Review</h6>
                  <p>Our team <span className='bold'>reviews</span> every submission that comes in for thesis fit.</p>
                </div>
                <div className="box">
                  <h4 className="number-box">3</h4>
                  <h6>First Meeting</h6>
                  <p>A <span className='bold'>30-minute meeting</span> for us to get to know each other.<br/>No pitching is required.</p>
                </div>
                <div className="box">
                  <h4 className="number-box">4</h4>
                  <h6>Deal assessment & decision by partners</h6>
                  <p>We'll make an <span className='bold'>informed decision</span> using our framework<br/>and follow up with the next steps.</p>
                  <Image src={DecorBottom} className="decor_bottom" />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Element>
    </div>
  );
};

export default Process;
