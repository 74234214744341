import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Image } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive';
import 'react-circular-progressbar/dist/styles.css';
// import TwitterIcon from '../../../assets/images/twitter_grey.png';
import TwitterIcon from '../../../assets/images/twitter-birds.svg';
import DuriyaText from '../../../assets/images/duriya_text.png';
import ElizabethText from '../../../assets/images/elizabeth_text.png';
import LoremText from '../../../assets/images/lorem_text.png';
// import Duriya from '../../../assets/images/duriya.png';
import Duriya from '../../../assets/images/Duriya.jpeg';
import Elizabeth from '../../../assets/images/elizabeth.png';
import Lorem from '../../../assets/images/lorem.png';
import { Row } from 'react-bootstrap';


const FundAppreciation = () => {
  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)'
  });

  const withMobileClass = (className) => {
    return isNotMobile ? className : `d-none`
  };

  return (
    <Row className={withMobileClass("fund-appreciation-wrapper d-none")}>
      <Row className={withMobileClass("container")}>
        <div className={withMobileClass("container-block")}>
          <h4>Fund <span>Appreciation</span></h4>
          <div className={withMobileClass("chart-group")}>
            <div className={withMobileClass("appr-box")}>
              <Image src={TwitterIcon} className="twitter" />
              <p className="text-1">You're a breath of fresh air for VC twitter. Thank YOU for amplifying overlooked leaders.</p>
              <div className="avatar-name">
                <Image src={Duriya} style={{ borderRadius:'50%' }}/>
                <div className="name">
                  <h8>Duriya Farooqui</h8>
                  <p>@duriyaFarooqui</p>
                </div>
              </div>
            </div>
            <div className={withMobileClass("appr-box")}>
              <Image src={TwitterIcon} className="twitter" />
              <p className="text-2">🙏🙏🙏 Thank you, Janine, for your infectious energy and jumping in and making a big impact on the startup ecosystem!</p>
              <div className="avatar-name">
                <Image src={Elizabeth} />
                <div className="name">
                  <h8>Elizabeth Yin</h8>
                  <p>@dunkhippo33</p>
                </div>
              </div>
            </div>
            <div className={withMobileClass("appr-box")}>
              <Image src={TwitterIcon} className="twitter" />
              <p className="text-3">Dear OverlookedVC team - you guys are literally doing a great job. I got some very useful resoures from you. As a startup founder, I needed those resources. Thank you!</p>
              <div className="avatar-name">
                <Image src={Lorem} style={{borderRadius:"50%"}} />
                <div className="name">
                  <h8>Mohammed Aamir Ali</h8>
                  <p>@aamirdiwan000</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>
    </Row>
  );
};

export default FundAppreciation;
