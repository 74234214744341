import React, { useState, useEffect } from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import Typist from 'react-typist';
// import LogoLight from '../../../assets/images/banner_image.png'
import LogoLight from '../../../assets/images/header_image.jpg'

const Overview = () => {
  const [count, setCount] = useState(1);

  useEffect(() => {
    setCount(1);
  }, [count]);

  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)'
  });

  const withMobileClass = (className) => {
    return isNotMobile ? className : `${className} ${className+'--mobile'}`
  };

  return (
    <div className={withMobileClass("overview-wrapper")}>
      <div className={withMobileClass("container")}>
        <Row className={withMobileClass("overview-content")} id="home_content">
          <Col md={6} xs={12} className="left-side header-item d-flex align-items-center">
            <div>
              <div className="upperline">DEPLOYING CAPITAL INTO HISTORICALLY IGNORED TALENT</div>
              <h1 className={isNotMobile ? '' : 'h1_mobile'}>We invest in</h1>
              { count ? (
                <Typist
                  avgTypingDelay={100}
                  cursor={{ show: true, hideWhenDone: false, element: '|' }}
                  onTypingDone={() => setCount(0)}
                  cursorBlinkSpeed={400}
                >
                  <span className={`typing ${isNotMobile ? '' : 'h1_mobile'}`}>overlooked</span>
                  <Typist.Backspace count={10} delay={1800} />
                  <span className={`typing ${isNotMobile ? '' : 'h1_mobile'}`}>fearless</span>
                  <Typist.Backspace count={8} delay={1800} />
                  <span className={`typing ${isNotMobile ? '' : 'h1_mobile'}`}>scrappy</span>
                  <Typist.Backspace count={7} delay={1800} />
                  <span className={`typing ${isNotMobile ? '' : 'h1_mobile'}`}>curious</span>
                  <Typist.Backspace count={7} delay={1800} />
                </Typist>
              ) : (
                <h1 className={isNotMobile ? '' : 'h1_mobile'}> </h1>
              )}
              <h1 className={isNotMobile ? '' : 'h1_mobile'}>founders</h1>
            </div>
          </Col>
          <Col md={6} xs={12} className={`${withMobileClass("right-side")} header-item`}>
            <Image src={LogoLight} className={withMobileClass('banner')} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Overview;