import PipeLogo from '../../../assets/images/pipe_logo.png';
// import PipeLogoSquare from '../../../assets/images/pipe_logo_square.png';
import PipeLogoSquare from '../../../assets/images/pipe-logo-icon.png';
import PipeBg from '../../../assets/images/pipe_bg.png';
import StaggerLogo from '../../../assets/images/stagger_logo.png';
import StaggerLogoSquare from '../../../assets/images/stagger_logo_square.png';
import StaggerBg from '../../../assets/images/stagger_bg.png';
import WestTenthLogo from '../../../assets/images/west_tenth_logo.png';
import WestTenthLogoSquare from '../../../assets/images/west_tenth_logo_square.png';
import WestTenthBg from '../../../assets/images/west_tenth_bg.png';
import PrometeoLogo from '../../../assets/images/prometeo_logo.png';
import PrometeoLogoSquare from '../../../assets/images/prometeo_icon.png';
// import PrometeoLogoSquare from '../../../assets/images/prometeo_logo_square.png';
import PrometeoBg from '../../../assets/images/prometeo_bg.png';
import CoupletCoffeeLogo from '../../../assets/images/couplet_coffee_logo.png';
import CoupletCoffeeLogoSquare from '../../../assets/images/couplet_coffee_logo_square.png';
import CoupletCoffeeBg from '../../../assets/images/couplet_coffee_bg.png';
import InfiniteObjectsLogo from '../../../assets/images/infinite_objects_logo.png';
import InfiniteObjectsLogoSquare from '../../../assets/images/infinite_objects_logo_square.png';
import InfiniteObjectsBg from '../../../assets/images/infinite_objects_bg.png';
import AkashSystemsLogo from '../../../assets/images/akash_systems_logo.png';
import AkashSystemsLogoSquare from '../../../assets/images/akash_systems_logo_square.png';
import AkashSystemsBg from '../../../assets/images/akash_systems_bg.png';
import BigBlackTeaLogo from '../../../assets/images/big_black_tea_logo.png';
import BigBlackTeaLogoSquare from '../../../assets/images/big_black_tea_logo_square.png';
import BigBlackTeaBg from '../../../assets/images/big_black_tea_bg.png';
import GuideLogo from '../../../assets/images/guide_logo.png';
import GuideLogoSquare from '../../../assets/images/guide_logo_square.png';
import GuideBg from '../../../assets/images/guide_bg.png';
import Cobble from '../../../assets/images/cobble_logo.svg';
import CobblePlatform from '../../../assets/images/cobble_platform.png';
import Leda from '../../../assets/images/leda_health.svg';
import LedaPlatform from '../../../assets/images/leda_platform.png';

const Data = [
  {
    id: 1,
    logo: PipeLogo,
    logoSquare: PipeLogoSquare,
    bg: PipeBg,
    name: 'Pipe',
    kind: 'FinTech, Consumer',
    url: 'https://pipe.com',
    description: 'Pipe transforms recurring revenue into upfront capital for growth without debt or dilution.',
    founder: 'Founder: Harry Hurst',
  },
  {
    id: 2,
    logo: Cobble,
    logoSquare: Cobble,
    bg: CobblePlatform,
    name: 'Cobble',
    kind: 'SaaS',
    url: 'https://www.gocobble.com/',
    description: 'Cobble is an all-in-one app for entrepreneurs to build and grow their company with tools for business planning, marketing, financials, contact management, and fundraising.',
    founder: 'Founder: Janine Sickmeyer',
  },
  {
    id: 3,
    logo: CoupletCoffeeLogo,
    logoSquare: CoupletCoffeeLogoSquare,
    bg: CoupletCoffeeBg,
    name: 'Couplet coffee',
    kind: 'Consumer packaged goods (CPG)',
    url: 'https://coupletcoffee.com',
    description: 'Curated sustainably sourced coffee subscription',
    founder: 'Founder: Gefen Skoinick',
  },
  {
    id: 4,
    logo: StaggerLogo,
    logoSquare: StaggerLogoSquare,
    bg: StaggerBg,
    name: 'Stagger',
    kind: 'Consumer, Social, Marketing Tech, Saas',
    url: 'https://stagger.it',
    description: 'Stagger helps brands tell their story visually so that they can stand out.',
    founder: 'Founder: Chrissy Cowdrey',
  }, 
  {
    id: 5,
    logo: WestTenthLogo,
    logoSquare: WestTenthLogoSquare,
    bg: WestTenthBg,
    name: 'West Tenth',
    kind: 'Consumer, Social',
    url: 'https://westtenth.com',
    description: 'Local digital marketplace that connects home-based businesses and buyers.',
    founder: 'Founder: Lyn Johnson',
  },
  {
    id: 6,
    logo: InfiniteObjectsLogo,
    logoSquare: InfiniteObjectsLogoSquare,
    bg: InfiniteObjectsBg,
    name: 'Infinite Objects',
    kind: 'Consumer, Hardware',
    url: 'https://infiniteobjects.com',
    description: 'Print your videos and purchase video art',
    founder: 'Founder: Joseph Saavedra',
  },
  {
    id: 7,
    logo: PrometeoLogo,
    logoSquare: PrometeoLogoSquare,
    bg: PrometeoBg,
    name: 'Prometeo',
    kind: 'FinTech, B2B',
    url: 'https://prometeoapi.com',
    description: 'Open banking platform in Latin America',
    founder: 'Founder: Ximena Aleman',
  },
  {
    id: 8,
    logo: Leda,
    logoSquare: Leda,
    bg: LedaPlatform,
    name: 'Leda Health',
    kind: 'Health Tech',
    url: 'https://www.leda.co/',
    description: 'Leda Health helps survivors, their loved ones, and the communities in which they live with prevention, care, and healing.',
    founder: 'Founder: Madison Campbell and Liesel Vaidya ',
  },
  {
    id: 9,
    logo: AkashSystemsLogo,
    logoSquare: AkashSystemsLogoSquare,
    bg: AkashSystemsBg,
    name: 'Akash Systems',
    kind: 'Aerospace, Telecommunications',
    url: 'https://akashsystems.com',
    description: 'Satellite communications to help push the world towards universal and low-cost access to internet.',
    founder: 'Founder: Felix Ejeckam',
  },
  {
    id: 10,
    logo: BigBlackTeaLogo,
    logoSquare: BigBlackTeaLogoSquare,
    bg: BigBlackTeaBg,
    name: 'Big Black Tea',
    kind: 'Consumer packaged goods (CPG)',
    url: 'https://bigblacktea.com',
    description: 'Black-owned global premium tea.',
    founder: 'Founders: Tim Salau and Lindsey Peoples Tod',
  },
  {
    id: 11,
    logo: GuideLogo,
    logoSquare: GuideLogoSquare,
    bg: GuideBg,
    name: 'Guide',
    kind: 'Social, B2B, FutureofWork, Saas',
    url: 'https://guideapp.co',
    description: 'Byte-sized training videos for remote teams',
    founder: 'Founders: Tim Salau and Taban Cosmos',
  }
]

export default Data;