import React from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import { Element } from 'react-scroll'
import { useMediaQuery } from 'react-responsive';
// import Janine from '../../../assets/images/janine.svg';
import Janine from '../../../assets/images/janine.png';
// import Jaime from '../../../assets/images/Jaime.svg';
import Jaime from '../../../assets/images/Jaime.jpg';
// import Adam from '../../../assets/images/adam.svg';
import Adam from '../../../assets/images/adam.jpg';
// import Chaun from '../../../assets/images/chauntelle.svg';
import Chaun from '../../../assets/images/chauntelle.jpg';
import Twitter from '../../../assets/images/twitter.svg';
import Linkedin from '../../../assets/images/linkedin_logo.svg';
import Email from '../../../assets/images/email.svg';
// import Vanezah from '../../../assets/images/vanejah.jpeg';
import Vanezah from '../../../assets/images/vanezah.png';
import Tyshia from '../../../assets/images/tyshia_ingram.png';

const PartnerHeader = () => {
  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)'
  });

  const withMobileClass = (className) => {
    return isNotMobile ? className : `${className} ${className+'--mobile'}`
  };

  return (
    <div className={withMobileClass("partner-header-wrapper")}>
      <Element className={withMobileClass("container")} name="team">
        {isNotMobile ? (
          <>
          <Row style={{height:'unset'}}>
            <Col sm="6" className="container-right">
              <div className="line-container">
                <div className="line" />
              </div>
              <div className="stats-content">
                <div className="stats-block">
                  <div className="upperline">TEAM</div>
                  <h2 className={isNotMobile ? '' : 'h2_mobile'}><span className={`${isNotMobile ? '' : 'h2_mobile'} typing`}>Who</span> We Are</h2>
                </div>
              </div>
            </Col>
            <Col sm="6" className="container-left">
              <div className="box-text">
                <p>
                  General Partner, Janine Sickmeyer, has <span className="bold">startup experience, strong access to deal flow, and 12+ years of startup and investing experience.</span>
                </p>
                <p>
                  Founded in May of 2021 by Janine Sickmeyer and Brandon Brooks, Overlooked Ventures is committed to backing founders at the earliest stages. Our team is made up of hard-working bootstrapped founders turned investors who are creating equitable opportunities for historically ignored entrepreneurs.
                </p>
                <p>Meet the team supporting the Overlooked portfolio.</p>
              </div>
            </Col>
          </Row>
          <Row style={{height:'unset', marginTop: '120px'}} className="person-section">
            <Col md="4" className="person-card">
              <Image src={Janine} height={364} className="person-img"/>
              <div className='content' style={{ marginTop:"2rem"}}>
                <div className='name'>Janine Sickmeyer</div>
                <div className='position'>FOUNDING PARTNER</div>
                <div className='links'>
                  <a href="https://twitter.com/myfriendjanine" target="_blank" rel="noreferrer"><Image src={Twitter}/></a>
                  {/* <a href="https://twitter.com/myfriendjanine" target="_blank"><Image src={Instagram}/></a> */}
                  <a href="https://www.linkedin.com/in/janinesickmeyer/" target="_blank" rel="noreferrer"><Image src={Linkedin}/></a>
                  <a href="janine@overlookedventures.com" target="_blank"><Image src={Email}/></a>
                </div>
              </div>
            </Col>
            <Col md="4" className="person-card flex-column align-items-center">
              <Image src={Jaime} height={364} className="jaime person-img" />
              <div className='content jaime-content' style={{marginTop:"2rem", marginLeft:'-1rem'}}>
                <div className='name'>Jaime Houssami</div>
                <div className='position'>chief of staff</div>
                <div className='links'>
                <a href="https://twitter.com/jaimehoussami" target="_blank" rel="noreferrer"><Image src={Twitter}/></a>
                  {/* <a href="" target="_blank"><Image src={Instagram}/></a> */}
                  <a href="https://www.linkedin.com/in/jaime-houssami-45507469/" target="_blank" rel="noreferrer"><Image src={Linkedin}/></a>
                  <a href="jaime@overlookedventures.com" target="_blank"><Image src={Email}/></a>
                </div>
              </div>
            </Col>
            <Col md="4" className="person-card">
              <Image src={Adam} height={364} className="person-img"/>
              <div className='content' style={{marginTop:"2rem"}}>
                <div className='name'>Adam Grant</div>
                <div className='position'>PRINCIPAL</div>
                <div className='links'>
                  <a href="https://twitter.com/VenturewithAdam" target="_blank" rel="noreferrer"><Image src={Twitter}/></a>
                  {/* <a href="jaime@overlookedventures.com" target="_blank"><Image src={Instagram}/></a> */}
                  <a href="https://www.linkedin.com/in/adamchristophergrant/" target="_blank" rel="noreferrer"><Image src={Linkedin}/></a>
                  <a href="adam@overlookedventures.com" target="_blank"><Image src={Email}/></a>
                </div>
              </div>
            </Col>
            <Col md="4" className="person-card">
              <Image src={Chaun} height={364} className="chaun person-img" />
              <div className='content chaun-content' style={{ marginTop:"2rem"}}>
                <div className='name'>Chauntelle Lewis</div>
                <div className='position'>Inclusive Communities Manager</div>
                <div className='links'>
                  <a href="https://twitter.com/cjnlewis_" target="_blank" rel="noreferrer"><Image src={Twitter}/></a>
                  {/* <a href="https://twitter.com/VenturewithAdam" target="_blank"><Image src={Instagram}/></a> */}
                  <a href="https://www.linkedin.com/in/cjnlewis/" target="_blank" rel="noreferrer"><Image src={Linkedin}/></a>
                  <a href="chauntelle@overlookedventures.com" target="_blank"><Image src={Email}/></a>
                </div>
              </div>
            </Col>
            <Col md="4" className="person-card">
              <Image src={Tyshia} height={364} className="person-img"/>
              <div className='content' style={{ marginTop:"2rem"}}>
                <div className='name'>Tyshia Ingram</div>
                <div className='position'>portfolio content manager</div>
                <div className='links'>
                  <a href="https://twitter.com/Tyshia_Shante" target="_blank" rel="noreferrer"><Image src={Twitter}/></a>
                  {/* <a href="https://twitter.com/cjnlewis_" target="_blank"><Image src={Instagram}/></a> */}
                  <a href="https://www.linkedin.com/in/tyshia-ingram/" target="_blank" rel="noreferrer"><Image src={Linkedin}/></a>
                  <a href="tyshia@overlookedventures.com" target="_blank"><Image src={Email}/></a>
                </div>
              </div>
            </Col>
            <Col md="4" className="person-card">
              <Image src={Vanezah} height={364} className="vanezah person-img"/>
              <div className='content vanzah-content' style={{ marginTop:"2rem"}}>
                <div className='name'>Vaneezeh Siddiqui</div>
                <div className='position'>VENTURE INTERN</div>
                <div className='links'>
                  <a href=" https://twitter.com/vaneezehs" target="_blank"><Image src={Twitter}/></a>
                  {/* <a><Image src={Instagram}/></a> */}
                  <a href="https://www.linkedin.com/in/vaneezehs/" target="_blank" rel="noreferrer"><Image src={Linkedin}/></a>
                  <a href="vaneezeh@overlookedventures.com" target="_blank"><Image src={Email}/></a>
                </div>
              </div>
            </Col>
          </Row>
          </>
        ) : (
          <>
            <Row>
              <Col md={6} xs={12} className="container-right">
                <div className="line-container">
                  <div className={withMobileClass("line")} />
                </div>
                <div className="stats-content">
                  <div className={withMobileClass("stats-block")}>
                    <div className="upperline">Team</div>
                    <h2 className={isNotMobile ? '' : 'h2_mobile'}><span className="typing">Who</span> We Are</h2>
                    {/* <h2 className={isNotMobile ? '' : 'h2_mobile'}><span className="typing">&</span> Advisors</h2> */}
                  </div>
                </div>
              </Col>
              <Col md={6} xs={12} className="container-left">
                <div className={withMobileClass("box-text")}>
                  <p>
                    Our Founders collectively have <span className="bold">startup experience, strong access to deal flow, and 12+ years of startup and investing experience.</span>
                  </p>
                  <p>
                    Here at Overlooked Ventures, our team is made up of hard-working bootstrapped founders turned investors creating equitable opportunities for historically ignored founders.
                  </p>
                </div>
              </Col>
            </Row>
            <Row style={{ height: 'unset', marginTop: '120px' }} className="person-section">
              <Col md="4" className="person-card">
                <Image src={Janine} className='person-img'/>
                <div className='content'  style={{ marginTop: "2rem" }}>
                  <div className='name'>Janine Sickmeyer</div>
                  <div className='position'>FOUNDING PARTNER</div>
                  <div className='links'>
                    <a href="https://twitter.com/myfriendjanine" target="_blank" rel="noreferrer"><Image src={Twitter} /></a>
                    {/* <a href="https://twitter.com/myfriendjanine" target="_blank"><Image src={Instagram}/></a> */}
                    <a href="https://www.linkedin.com/in/janinesickmeyer/" target="_blank" rel="noreferrer"><Image src={Linkedin} /></a>
                    <a href="janine@overlookedventures.com" target="_blank"><Image src={Email} /></a>
                  </div>
                </div>
              </Col>
              <Col md="4" className="person-card">
                <Image src={Jaime} height={364} className="jaime-img person-img"/>
                <div className='content jaime-content' style={{ marginLeft: "1rem", marginTop: "2rem" }}>
                  <div className='name'>Jaime Houssami</div>
                  <div className='position'>chief of staff</div>
                  <div className='links'>
                    <a href="https://twitter.com/jaimehoussami" target="_blank" rel="noreferrer"><Image src={Twitter} /></a>
                    {/* <a href="" target="_blank"><Image src={Instagram}/></a> */}
                    <a href="https://www.linkedin.com/in/jaime-houssami-45507469/" target="_blank" rel="noreferrer"><Image src={Linkedin} /></a>
                    <a href="jaime@overlookedventures.com" target="_blank"><Image src={Email} /></a>
                  </div>
                </div>
              </Col>
              <Col md="4" className="person-card">
                <Image src={Adam} height={364} className='person-img'/>
                <div className='content' style={{ marginTop: "2rem" }} >
                  <div className='name'>Adam Grant</div>
                  <div className='position'>PRINCIPAL</div>
                  <div className='links'>
                    <a href="https://twitter.com/VenturewithAdam" target="_blank" rel="noreferrer"><Image src={Twitter} /></a>
                    {/* <a href="jaime@overlookedventures.com" target="_blank"><Image src={Instagram}/></a> */}
                    <a href="https://www.linkedin.com/in/adamchristophergrant/" target="_blank" rel="noreferrer"><Image src={Linkedin} /></a>
                    <a href="adam@overlookedventures.com" target="_blank"><Image src={Email} /></a>
                  </div>
                </div>
              </Col>
              <Col md="4" className="person-card">
                <Image src={Chaun} height={364} className="chaun person-img"/>
                <div className='content chaun content' style={{ marginTop: "2rem" }}>
                  <div className='name'>Chauntelle Lewis</div>
                  <div className='position'>Inclusive Communities Manager</div>
                  <div className='links'>
                    <a href="https://twitter.com/cjnlewis_" target="_blank" rel="noreferrer"><Image src={Twitter} /></a>
                    {/* <a href="https://twitter.com/VenturewithAdam" target="_blank"><Image src={Instagram}/></a> */}
                    <a href="https://www.linkedin.com/in/cjnlewis/" target="_blank" rel="noreferrer"><Image src={Linkedin} /></a>
                    <a href="chauntelle@overlookedventures.com" target="_blank"><Image src={Email} /></a>
                  </div>
                </div>
              </Col>
              <Col md="4" className="person-card">
                <Image src={Tyshia} height={364} className='person-img'/>
                <div className='content' style={{ marginTop: "2rem" }}>
                  <div className='name'>Tyshia Ingram</div>
                  <div className='position'>portfolio content manager</div>
                  <div className='links'>
                    <a href="https://twitter.com/Tyshia_Shante" target="_blank" rel="noreferrer"><Image src={Twitter} /></a>
                    {/* <a href="https://twitter.com/cjnlewis_" target="_blank"><Image src={Instagram}/></a> */}
                    <a href="https://www.linkedin.com/in/tyshia-ingram/" target="_blank" rel="noreferrer"><Image src={Linkedin} /></a>
                    <a href="tyshia@overlookedventures.com" target="_blank"><Image src={Email} /></a>
                  </div>
                </div>
              </Col>
              <Col md="4" className="person-card">
                <Image src={Vanezah} height={364} className="vanezah person-img"/>
                <div className='content vanezah-content' style={{ marginTop: "2rem" }}>
                  <div className='name'>Vaneezeh Siddiqui</div>
                  <div className='position'>VENTURE INTERN</div>
                  <div className='links'>
                    <a href=" https://twitter.com/vaneezehs" target="_blank"><Image src={Twitter} /></a>
                    {/* <a><Image src={Instagram}/></a> */}
                    <a href="https://www.linkedin.com/in/vaneezehs/" target="_blank" rel="noreferrer"><Image src={Linkedin} /></a>
                    <a href="vaneezeh@overlookedventures.com" target="_blank"><Image src={Email} /></a>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Element>
    </div>
  );
};

export default PartnerHeader;
