import React, { useState } from 'react';
import { Image, Button } from 'react-bootstrap';
import { Link } from 'react-scroll'
import { useMediaQuery } from 'react-responsive';
import LogoLight from '../../../assets/images/logo_light.png'
import NavIcon from '../../../assets/images/nav_icon.svg'
import XIcon from '../../../assets/images/x_icon.svg'

const Header = () => {
  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)'
  });

  const [showMenu, setShowMenu] = useState(false);

  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  }

  const withMobileClass = (className) => {
    return isNotMobile ? className : `${className} ${className+'--mobile'}`
  }

  return (
    <div className={`${withMobileClass('header-wrapper')} ${showMenu ? 'header-wrapper--active' : ''}`}>
      <div className={`${withMobileClass('header-content')} ${withMobileClass('container')}`}>
        { isNotMobile ? (
          <Link
            to="home_content"
            spy={true}
            smooth={true}
            duration={500}
            onClick={() => setShowMenu(false)}
            // className={''}
          >
            <Image src={LogoLight} className="header-logo"/>
          </Link>
        ) : (
          <div className="logo-box">
            <Image src={LogoLight} className="header-logo" />
            <Button
              className="nav-button"
              onClick={() => handleShowMenu()}
            >
              <Image src={showMenu ? XIcon : NavIcon} className={showMenu ? 'x-img' : 'nav-img'} />
            </Button>
          </div>
        )}
        <div className={`${withMobileClass('header-menu')} ${showMenu ? 'header-menu--active' : ''}`}>
          <Link
            to="philosophy-content"
            spy={true}
            smooth={true}
            duration={500}
            onClick={() => setShowMenu(false)}
            className={withMobileClass('header-menu__item')}
          >
            PHILOSOPHY
          </Link>
          <Link
            to="thesis-record"
            spy={true}
            smooth={true}
            duration={500}
            onClick={() => setShowMenu(false)}
            className={withMobileClass('header-menu__item')}
          >
            THESIS
          </Link>
          <Link
            to="who-we-are"
            spy={true}
            smooth={true}
            duration={500}
            onClick={() => setShowMenu(false)}
            className={withMobileClass('header-menu__item')}
          >
            MISSION
          </Link>
          <Link
            to="track-record"
            spy={true}
            smooth={true}
            duration={500}
            onClick={() => setShowMenu(false)}
            className={withMobileClass('header-menu__item')}
          >
            TRACK RECORD
          </Link>
          <Link
            to="team"
            spy={true}
            smooth={true}
            onClick={() => setShowMenu(false)}
            duration={500}
            className={withMobileClass('header-menu__item')}
          >
            TEAM
          </Link>
          <Link
            to="process_content"
            spy={true}
            smooth={true}
            onClick={() => setShowMenu(false)}
            duration={500}
            className={withMobileClass('header-menu__item')}
          >
            PROCESS
          </Link>
          <Link
            to="apply"
            spy={true}
            smooth={true}
            onClick={() => setShowMenu(false)}
            duration={500}
            className={withMobileClass('header-menu__button')}
          >
            <span>APPLY</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;