import React from 'react';
import Advantage from './components/Advantage';
import Advisory from './components/Advisory';
import Apply from './components/Apply';
import Diveser from './components/Diverse';
import Footer from './components/Footer';
import FounderNetwork from './components/FounderNetwork';
import Header from './components/Header'
import News from './components/News'
import Overview from './components/Overview';
import Partner from './components/Partner';
import Partners from './components/Partners';
import PartnerHeader from './components/PartnerHeader';
import Philosophy from './components/Philosophy';
import Process from './components/Process';
import SliderContent from './components/Slider';
import FundAppreciation from './components/FundAppreciation';
import FundSlider from './components/FundSlider';
import Stats from './components/Stats';
import TrackRecord from './components/TrackRecord';
import Portfolio from './components/Portfolio';
import WhoWeAre from './components/WhoWeAre';

const Home = () => {
  return (
    <div style={{overflow:'hidden'}}>
      <Header />
      <Overview />
      <Philosophy />
      <Stats />
      <Diveser />
      <TrackRecord />
      {/* <Portfolio /> */}
      <FundSlider />
      <WhoWeAre />
      <PartnerHeader />
      <SliderContent />
      <Partner />
      {/* <Advisory /> */}
      {/* <FounderNetwork /> */}
      <Advantage />
      <Process />
      <Partners />
      <FundAppreciation />
      <Apply />
      <News />
      <Footer />
    </div>
  );
};

export default Home;
