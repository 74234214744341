import React from 'react';
import { Image } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import Brandon from '../../../assets/images/brandon.png'
import Janine from '../../../assets/images/janine.png'
import FirstLineIcon from '../../../assets/images/first_line_icon.svg'
import TwitterIcon from '../../../assets/images/twitter_icon.svg'
import LinkedinIcon from '../../../assets/images/linkedin.svg'
import InstaIcon from '../../../assets/images/instagram_icon.svg'
import EmailIcon from '../../../assets/images/email_icon.svg'
import { Row } from 'react-bootstrap';


const Partner = () => {
  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)'
  });

  const withMobileClass = (className) => {
    return isNotMobile ? className : `${className} ${className+'--mobile'}`
  };

  return (
    <Row className={withMobileClass("partner-wrapper d-none")}>
      <div className={withMobileClass("container")}>
        <div className={withMobileClass("first-row")}>
          <Image className="founder" src={Janine}/>
          <div>
            <div className="upperline">FOUNDING PARTNER</div>
            <h4>Janine Sickmeyer</h4>
            <div className="item">
              <Image src={FirstLineIcon} />
              <div>
                <p>Founder and CEO of a legal tech SaaS company</p>
                <p>bootstrapped to acquisition in 6 years while having 4 kids.</p>
              </div>
            </div>
            <div className="item">
              <Image src={FirstLineIcon} />
              <div>
                <p>Angel investor and advisor for tech startups with</p>
                <p>overlooked founders.</p>
              </div>
            </div>
            <div className="item">
              <Image src={FirstLineIcon} />
              <div>
                <p>Featured in Forbes as  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.forbes.com/sites/allysonkapin/2020/12/10/51-vcs-who-want-to-invest-in-women-black-and-latinx-and-lgbtq-founders/"
                    className="typing"
                  >
                    51 VCs Who Want To Invest In
                  </a>
                </p>
                <p>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.forbes.com/sites/allysonkapin/2020/12/10/51-vcs-who-want-to-invest-in-women-black-and-latinx-and-lgbtq-founders/"
                    className="typing"
                  >
                    Women, Black And Latinx, And LGBTG+ Founders.
                  </a>
                </p>
              </div>
            </div>
            <div className="item">
              <Image src={FirstLineIcon} />
              <div>
                <p>Named as Business Insider Rising Star VCs</p>
              </div>
            </div>
            <div className="info-group">
              <a
                href="https://twitter.com/myfriendjanine"
                target="_blank"
                rel="noreferrer"
              >
                <Image src={TwitterIcon} />
              </a>
              <a
                href="https://www.linkedin.com/in/janinesickmeyer/"
                target="_blank"
                rel="noreferrer"
              >
                <Image src={LinkedinIcon} />
              </a>
              <a
                href="mailto:janine@overlookedventures.com"
                target="_blank"
                rel="noreferrer"
              >
                <Image src={EmailIcon} />
              </a>
            </div>
          </div>
        </div>

        <div className={withMobileClass("second-row")}>
          <div style={{marginLeft:'5px'}}>
            <div className="upperline">FOUNDING PARTNER</div>
            <h4>Brandon Brooks</h4>
            <div className="item">
              <Image src={FirstLineIcon} />
              <div>
                <p>Helps connect with venture capitalists and alternative</p>
                <p>forms of funding to help under-represented founders</p>
                <p>scale their startups.</p>
              </div>
            </div>
            <div className="item">
              <Image src={FirstLineIcon} />
              <div>
                <p>Founder and CEO of Inventrify, community oriented</p>
                <p>crowdfunding platform for small businesses.</p>
              </div>
            </div>
            <div className="item">
              <Image src={FirstLineIcon} />
              <div>
                <p>Twitter Spaces Spark Program Winner & Member of the</p>
                <p>Tech Council for City of Columbia, SC</p>
              </div>
            </div>
            <div className="info-group">
              <a
                href="https://twitter.com/OfficialBBrooks"
                target="_blank"
                rel="noreferrer"
              >
                <Image src={TwitterIcon} />
              </a>
              <a
                href="https://www.linkedin.com/in/inventrify/"
                target="_blank"
                rel="noreferrer"
              >
                <Image src={LinkedinIcon} />
              </a>
              <a
                href="mailto:brandon@overlookedventures.com"
                target="_blank"
                rel="noreferrer"
              >
                <Image src={EmailIcon} />
              </a>
            </div>
          </div>
          <Image className="founder" src={Brandon} width={420} height={610} />
        </div>
      </div>
    </Row>
  );
};

export default Partner;
