import React from 'react';
import { useMediaQuery } from 'react-responsive';

const Advantage = () => {
  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)'
  });

  const withMobileClass = (className) => {
    return isNotMobile ? className : `${className} ${className+'--mobile'}`
  };

  return (
    <div className={withMobileClass("advantage-wrapper")}>
      <div className={withMobileClass("container")}>
        <h4>The Overlooked <span className="typing">Advantage</span></h4>
        <div className={withMobileClass("box-group")}>
          <div className={withMobileClass("box")}>
            <h6>Roll-Up-Our-Sleeves</h6>
            <p>
              As bootstrapping founders and operators ourselves, we know what it takes to get on the ground and work for founders.
            </p>
          </div>
          <div className={withMobileClass("box")}>
            <h6>Part of the Community</h6>
            <p>
              Collectively, we are part of the community, have strong access to deal flow, and 12+ years of startup and investing experience.
            </p>
          </div>
          <div className={withMobileClass("box")}>
            <h6>Transparency First</h6>
            <p>
              We share candid feedback, give realistic expectations, and pipeline diversity stats for full transparency.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advantage;