import React from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import { Element } from 'react-scroll'
import { useMediaQuery } from 'react-responsive';
import LogoLight from '../../../assets/images/track_record.svg'
import Brain from '../../../assets/images/brain.svg';
import Taj from '../../../assets/images/taj.svg';
import Gear from '../../../assets/images/gear.svg';
import Skeleton from '../../../assets/images/skeleton.svg';
import Chair from '../../../assets/images/wheelchair.svg';
import Cold from '../../../assets/images/cold.svg';
import Hands from '../../../assets/images/hands.svg';
import Dollar from '../../../assets/images/dollar-coin.svg';
import Bed from '../../../assets/images/bed.svg';
import Home from '../../../assets/images/home.svg';
import Pine from '../../../assets/images/pineapple.svg';
import Car from '../../../assets/images/car.svg';

const TrackRecord = () => {
  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)'
  });

  const withMobileClass = (className) => {
    return isNotMobile ? className : `${className} ${className+'--mobile'}`
  };

  return (
    <div className="track-record-wrapper" id="thesis-record">
      <Element name="track_report_ventures" className={withMobileClass("container")}>
        {isNotMobile ? (
          <>
            <Row className='pre-seed-stage'>
              <div className="line-container">
                <div className="line" />
              </div>
              <div className='thesis'>Thesis</div>
              <div className='content'>
                Overlooked Ventures is a <span>pre-seed/seed-stage</span> fund investing in tech and tech-enabled startups building for <span>humanity, wellness</span>, and <span>industries lacking</span> innovation.
              </div>
              <Col sm="6" className="right-side header-item d-flex justify-content-end">
                {/* <Image src={LogoLight} width={420} height={500} style={{ objectFit: 'contain' }} /> */}
              </Col>
            {/* </Row>
            <Row> */}
              <div className='box-section'>
                <div className='box'>
                  <div className='head'>Wellness</div>
                  <div className='desc'>Startups that are creating technology and products to improve health.</div>
                  <div className='icons'>
                    <div className='icon'><Image src={Brain}/>Mental Health</div>
                    <div className='icon'><Image src={Taj}/>Femtech</div>
                    <div className='icon'><Image src={Gear}/>Healthcare Tech</div>
                  </div>
                </div>
                <div className='box'>
                  <div className='head'>Humanity</div>
                  <div className='desc'>Startups building to improve humanity, the lives of humans, and the world we live in.</div>
                  <div className='icons'>
                    <div className='icon'><Image src={Skeleton}/>Death Tech</div>
                    <div className='icon'><Image src={Chair}/>Age Tech</div>
                    <div className='icon'><Image src={Cold}/>Climate Tech</div>
                    <div className='icon'><Image src={Hands}/>Inclusion Tech</div>
                  </div>
                </div>
                <div className='box'>
                  <div className='head'>Industries Lacking Innovation</div>
                  <div className='desc'>Startups that are building solutions for industries in need of a change — think unsexy markets.</div>
                  <div className='icons'>
                    <div className='icon'><Image src={Dollar}/>Fintech</div>
                    <div className='icon'><Image src={Bed}/>Proptech</div>
                    <div className='icon'><Image src={Home}/>Construction Tech</div>
                    <div className='icon'><Image src={Pine}/>Agtech & Farmers</div>
                    <div className='icon'><Image src={Car}/>Mobility Tech</div>
                  </div>
                </div>
              </div>
            </Row>
            <Row className="track-record-content" name="track_report" id="track-record">
              <Col sm="6" className="left-side header-item">
                <div className="line-container">
                  <div className="line" />
                </div>
                <div>
                  <div className="upperline">TRACK RECORD</div>
                  <h2>Our <span className="typing">track record</span> of </h2>
                  <h2>fearless ventures</h2>
                </div>
              </Col>
              <Col sm="6" className="right-side header-item d-flex justify-content-end">
                <Image src={LogoLight} />
              </Col>
            </Row>
          </>
        ) : (
            <>
              <Row className='pre-seed-stage'>
              <div className="line-container">
                <div className="line" />
              </div>
              <div className='thesis'>Thesis</div>
              <div className='content'>
                Overlooked Ventures is a <span>pre-seed/seed-stage</span> fund investing in tech and tech-enabled startups building for <span>humanity, wellness</span>, and <span>industries lacking</span> innovation.
              </div>
              <Col sm="6" className="right-side header-item d-flex justify-content-end">
                {/* <Image src={LogoLight} width={420} height={500} style={{ objectFit: 'contain' }} /> */}
              </Col>
              <div className='box-section'>
                <div className='box'>
                  <div className='head'>Wellness</div>
                  <div className='desc'>Startups that are creating technology and products to improve health.</div>
                  <div className='icons'>
                    <div className='icon'><Image src={Brain}/>Mental Health</div>
                    <div className='icon'><Image src={Taj}/>Femtech</div>
                    <div className='icon'><Image src={Gear}/>Healthcare Tech</div>
                  </div>
                </div>
                <div className='box'>
                  <div className='head'>Humanity</div>
                  <div className='desc'>Startups building to improve humanity, the lives of humans, and the world we live in.</div>
                  <div className='icons'>
                    <div className='icon'><Image src={Skeleton}/>Death Tech</div>
                    <div className='icon'><Image src={Chair}/>Age Tech</div>
                    <div className='icon'><Image src={Cold}/>Climate Tech</div>
                    <div className='icon'><Image src={Hands}/>Inclusion Tech</div>
                  </div>
                </div>
                <div className='box'>
                  <div className='head'>Industries Lacking Innovation</div>
                  <div className='desc'>Startups that are building solutions for industries in need of a change — think unsexy markets.</div>
                  <div className='icons'>
                    <div className='icon'><Image src={Dollar}/>Fintech</div>
                    <div className='icon'><Image src={Bed}/>Proptech</div>
                    <div className='icon'><Image src={Home}/>Construction Tech</div>
                    <div className='icon'><Image src={Pine}/>Agtech & Farmers</div>
                    <div className='icon'><Image src={Car}/>Mobility Tech</div>
                  </div>
                </div>
              </div>
            </Row>
            <Row className="track-record-content">
              <Col md={6} xs={12} className={withMobileClass("right-side")}>
                <div className="line-container">
                  <div className={withMobileClass("line")} />
                </div>
                <div>
                  <div className="upperline">TRACK RECORD</div>
                  <h2 className={isNotMobile ? '' : 'h2_mobile'}>Our <span className={`${isNotMobile ? '' : 'h2_mobile'} typing`}>track record</span> of </h2>
                  <h2 className={isNotMobile ? '' : 'h2_mobile'}>fearless ventures</h2>
                </div>
              </Col>
              <Col md={6} xs={12} className="left-side">
                {/* <div className="line-container">
                  <div className={withMobileClass("line")} />
                </div> */}
                <Image src={LogoLight} fluid />
              </Col>
            </Row>
          </>
        )}
      </Element>
    </div>
  );
};

export default TrackRecord;