import React from 'react';
import { useMediaQuery } from 'react-responsive';

const Stats = () => {
  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)'
  });

  const withMobileClass = (className) => {
    return isNotMobile ? className : `${className} ${className+'--mobile'}`
  };

  return (
    <div className={withMobileClass("stats-wrapper")}>
      <div className={withMobileClass("container-left-fluid")}>
        <div className={withMobileClass("container-left")}>
          <div className={withMobileClass("box-row")}>
            <div className={`${withMobileClass("box-line")}`}>
              <div className={withMobileClass("box-item")}>
                <div className="box-item__content">
                  <h3>2.0%</h3>
                  <p className="small-text">Of all venture funding in 2021,</p>
                  <p className="small-text">only <span className="bold">2% went to women-only founded companies</span></p>
                  <a className="small-text link" href="https://pitchbook.com/news/articles/female-founders-dashboard-2021-vc-funding-wrap-up">(PitchBook)</a>
                </div>
              </div>
            </div>
            <div className={`${withMobileClass("box-line")} ${withMobileClass("box-line--right")}`}>
              <div className={withMobileClass("box-item")}>
                <div className="box-item__content">
                  <h3>$4 trillion</h3>
                  <p className="small-text">VCs are <span className="bold">leaving more than $4</span></p>
                  <p className="small-text"><span className="bold">trillion on the table</span> by not</p>
                  <p className="small-text">investing in a wider network of founders.</p>
                  <a className="small-text link" href="https://www.marketplace.org/shows/marketplace-tech/vcs-are-leaving-trillions-on-the-table-by-bypassing-diverse-leaders-study-says/">(HBR)</a>
                </div>
              </div>
            </div>
          </div>
          <div className={withMobileClass("box-row-second")}>
            <div className={`${withMobileClass("box-line")}`}>
              <div className={withMobileClass("box-item")}>
                <div className="box-item__content">
                  <h3>1.3%</h3>
                  <p className="small-text">Of all venture funding in 2021,</p>
                  <p className="small-text">only <span className="bold">1.3% went to Black-founded startups</span></p>
                  <a className="small-text link" href="https://news.crunchbase.com/news/highlighting-notable-funding-to-black-founders-in-2020/?utm_source=twitter&utm_medium=website&utm_campaign=SocialSnap">(Crunchbase)</a>
                </div>
              </div>
            </div>
            <div className={`${withMobileClass("box-line")} ${withMobileClass("box-line--right")}`}>
              <div className={withMobileClass("box-item")}>
                <div className="box-item">
                  <div className="box-item__content">
                    <h3>63%</h3>
                    <p className="small-text">Companies with a <span className="bold">female founder</span></p>
                    <p className="small-text"><span className="bold">performed 63% better</span> than</p>
                    <p className="small-text">investments with all-male founding</p>
                    <p className="small-text">teams <a className="small-text link" href="http://10years.firstround.com/#one">(First Round Copital)</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={withMobileClass("container-right-fluid")}>
        <div className={withMobileClass("container-right")}>
          <div className="line-container">
            <div className={withMobileClass("line")} />
          </div>
          <div className={withMobileClass("stats-content")}>
            <div className={withMobileClass("stats-block")}>
              <div className="upperline">STATS</div>
              <h2 className={isNotMobile ? '' : 'h2_mobile'}>Venture dollars are</h2>
              <h2 className={isNotMobile ? '' : 'h2_mobile'}>overlooking <span className="typing">massive</span></h2>
              <h2 className={`${isNotMobile ? '' : 'h2_mobile'} typing`}>opportunities</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;