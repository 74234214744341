import React from 'react';
import { Image, Button } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { Element } from 'react-scroll'
import Afrotech from '../../../assets/images/afro-tech.png';
import Forbes from '../../../assets/images/forbes-logo.png';
import Columbus from '../../../assets/images/columbus_tag.png';
import ColumbusBF from '../../../assets/images/columbus-new-logo.png';
import ArrowRight from '../../../assets/images/arrow_right_icon.svg';
import NVCALogo from '../../../assets/images/NVCA-Logo.png';
import BusinessInsiderLogo from '../../../assets/images/Business_Insider_Logo.png';
import { Row, Col } from 'react-bootstrap';

const News = () => {
  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)'
  });

  const withMobileClass = (className) => {
    return isNotMobile ? className : `${className} ${className+'--mobile'}`
  };

  return (
    <div className={withMobileClass('news-wrapper')}>
      <Element name="philosophy" className={withMobileClass('container')}>
        <Row className="partners-content">
          <h4>OV in <span>the News</span></h4>
          <div className='row justify-content-center align-items-start  logo-container flex-wrap'>
            <Col md='3' sm='12'>
              <div className={withMobileClass('box')}>
                <div className='box-title' style={{minHeight: '130px'}}>
                  <h6> Member Spotlight: Overlooked Ventures</h6>
                  <p>August 31, 2022</p>
                </div>
                <div className="slider-item">
                  <Image src={NVCALogo} className="logo" />
                  <Button
                    className="slider-item__show d-flex justify-content-center align-items-center"
                    href='https://nvca.org/overlooked-ventures/'
                    target="_blank"
                    >
                    <Image src={ArrowRight} />
                  </Button>
                </div>
              </div>
            </Col>
            <Col md='3' sm='12'>
              <div className={withMobileClass('box')}>
                <div className='box-title' style={{minHeight: '130px'}}>
                  <h6> Overlooked Ventures<br/>
                  lands Bank of America<br/>
                  as first institutional investor<br/>
                      </h6>
                  <p>March 31, 2022</p>
                </div>
                <div className="slider-item">
                  <Image src={Columbus} className="logo" />
                  <Button
                    className="slider-item__show d-flex justify-content-center align-items-center"
                    href='https://www.bizjournals.com/columbus/inno/stories/fundings/2022/03/31/bank-of-america-overlooked-ventures-vc.html'
                    target="_blank"
                    >
                    <Image src={ArrowRight} />
                  </Button>
                </div>
              </div>
            </Col>
            <Col md='3' sm='12'>
              <div className={withMobileClass('mb-2 box')}>
                <div className='box-title' style={{minHeight: '130px'}}>
                  <h6>Meet the 55 Rising Stars Of VC</h6>
                  <p>Oct 3, 2021</p>
                </div>
                <div className="slider-item">
                  <Image src={BusinessInsiderLogo} className="logo" />
                  <Button
                    className="slider-item__show d-flex justify-content-center align-items-center"
                    href='https://www.businessinsider.com/55-rising-stars-best-venture-capitalists-top-vcs-2021-12#janine-sickmeyer-44'
                    target="_blank"
                    >
                    <Image src={ArrowRight} />
                  </Button>
                </div>
              </div>
            </Col>
            <Col md='3' sm='12' className='d-none'>
              <div className={withMobileClass('mb-2 box')}>
                <div className='box-title' style={{minHeight: '130px'}}>
                  <h6>Overlooked Ventures<br/>
                      Launches<br/>
                      $50M Fund For Non-<br/>
                      Traditional Founders</h6>
                  <p>May 26, 2021</p>
                </div>
                <div className="slider-item">
                  <Image src={Afrotech} className="logo" />
                  <Button
                    className="slider-item__show d-flex justify-content-center align-items-center"
                    href='https://afrotech.com/overlooked-ventures-launches-fund'
                    target="_blank"
                    >
                    <Image src={ArrowRight} />
                  </Button>
                </div>
              </div>
            </Col>
            <Col md='3' sm='12'>
              <div className={withMobileClass('mb-2 box')}>
                <div className='box-title' style={{minHeight: '130px'}}>
                  <h6>Trailblazers: Janine<br/>
                      Sickmeyer, Founding<br/>
                      Partner of Overlooked<br/>
                      Ventures</h6>
                  <p>May 25, 2021</p>
                </div>
                <div className="slider-item">
                  <Image src={Forbes} className="logo" />
                  <Button
                    className="slider-item__show d-flex justify-content-center align-items-center"
                    href='https://www.forbes.com/sites/rebekahbastian/2021/05/25/trailblazers-janine-sickmeyer-founding-partner-of-overlooked-ventures/?sh=2a1b8af41c5e'
                    target="_blank"
                    >
                    <Image src={ArrowRight} />
                  </Button>
                </div>
              </div>
            </Col>
            {/* <Col md='3' sm='12'>
              <div className={withMobileClass('mb-2 box')}>
                <div className='box-title' style={{maxHeight: '130px'}}>
                  <h6>Entrepreneurs raising<br/>
                      $50M Overlooked<br/>
                      Ventures VC fund for, well,<br/>
                      the overlooked</h6>
                  <p>May 19, 2021</p>
                </div>
                <div className="slider-item">
                  <Image src={ColumbusBF} style={{ objectFit: 'cover' }} className="logo" />
                  <Button
                    className="slider-item__show d-flex justify-content-center align-items-center"
                    href='https://www.bizjournals.com/columbus/news/2021/05/19/columbus-sc-entrepreneurs-raising-50m-vc-fund.html'
                    target="_blank"
                    >
                    <Image src={ArrowRight} />
                  </Button>
                </div>
              </div>
            </Col> */}
          </div>
          <div className="press">
            <p>IN THE PRESS</p>
            <div className="news-box">
              <h6>Interview With Janine Sickmeyer </h6>
              <p>Clockwork | Aug 17, 2022</p>
               <a 
               href='https://www.clockwork.app/blog/interview-with-janine-sickmeyer' 
               target="_blank" rel="noreferrer">
                <Image src={ArrowRight} />
               </a>
            </div>
            <div className="news-box">
              <h6>VCs on a mission: 23 investors looking to fund women, Black, Latin and LGBTQ founders </h6>
              <p>Business of Business | Apr 12, 2022</p>
               <a 
               href='https://www.businessofbusiness.com/articles/vcs-women-black-latinx-and-lgbtq-founders/' 
               target="_blank" rel="noreferrer">
                <Image src={ArrowRight} />
               </a>
            </div>
            <div className="news-box">
              <h6>A look at six new funds begs the question: Is a slowdown really coming?</h6>
              <p>TechCrunch | Apr 11, 2022</p>
              <a href='https://techcrunch.com/2022/04/11/a-look-at-six-new-funds-begs-the-question-is-a-slowdown-really-coming/' target="_blank" rel="noreferrer"> 
                <Image src={ArrowRight} />
              </a>
            </div>
            <div className="news-box d-none">
              <h6>37 Black investors changing the VC industry with firms dedicated to funding diverse startup founders.</h6>
              <p>Business Insider | Feb 18, 2022</p>
              <a href='https://www.businessinsider.com/black-vc-investors-started-firms-invest-diverse-underrepresented-startup-founders-2022-2' target="_blank" rel="noreferrer"> 
                <Image src={ArrowRight} />
              </a>
            </div>
            <div className="news-box d-none">
              <h6>Black VCs Making a Big Impact</h6>
              <p>Venture Forward | Feb 18, 2022</p>
              <a href='https://ventureforward.org/blackvcsmakingabigimpact/#Brooks' target="_blank" rel="noreferrer"> 
                <Image src={ArrowRight} />
              </a>
            </div>
            <div className="news-box">
              <h6>55 Rising Stars in VC</h6>
              <p>Business Insider | Oct 3, 2021</p>
              <a href='https://www.businessinsider.com/55-rising-stars-best-venture-capitalists-top-vcs-2021-12#janine-sickmeyer-44' target="_blank" rel="noreferrer"> 
                <Image src={ArrowRight} />
              </a>
            </div>
            <div className="news-box">
              <h6>Women-Led Businesses Are Poised For A Comeback In 2022</h6>
              <p>Forbes | Jan 3, 2022</p>
              <a href='https://www.forbes.com/sites/amyschoenberger/2022/01/03/women-led-businesses-are-poised-for-a-comeback-in-2022/?sh=415ed85e65ec' target="_blank" rel="noreferrer"> 
                <Image src={ArrowRight} />
              </a>
            </div>
            <div className="news-box">
              <h6>Investing in Overlooked Founders with Janine Sickmeyer</h6>
              <p>Venturing in VC | Feb 3, 2022</p>
              <a href='https://podcasts.apple.com/us/podcast/investing-in-overlooked-founders-with-janine-sickmeyer/id1603468321?i=1000549876467' target="_blank" rel="noreferrer"> 
                <Image src={ArrowRight} />
              </a>
            </div>
            <div className="news-box">
              <h6>Overlooked Ventures has raised enough to make its first investments</h6>
              <p>The Business Journals | Oct 3, 2021</p>
              <a href='https://www.bizjournals.com/columbus/inno/stories/fundings/2021/11/05/overlooked-ventures-makes-first-investments.html#:~:text=Overlooked' target="_blank" rel="noreferrer"> 
                <Image src={ArrowRight} />
              </a>
            </div>
            <div className="news-box">
              <h6>More Equity: Janine Sickmeyer of Overlooked Ventures</h6>
              <p>Apple Podcasts | Oct 3, 2021</p>
              <a href='https://podcasts.apple.com/us/podcast/35-more-equity-janine-sickmeyer-of-overlooked-ventures/id1523705329?i=1000537425485' target="_blank" rel="noreferrer">
                <Image src={ArrowRight} />
              </a>
            </div>
            <div className="news-box">
              <h6>No Longer Overlooked: The Venture Capitalist Changing The Face Of Investing</h6>
              <p>Forbes | Oct 1, 2021</p>
               <a 
               href='https://www.forbes.com/sites/amyschoenberger/2021/10/01/no-longer-overlooked-the-venture-capitalist-changing-the-face-of-investing/?sh=7af223637205' 
               target="_blank" rel="noreferrer">
                <Image src={ArrowRight} />
               </a>
            </div>
            <div className="news-box">
              <h6>Remote Choice Podcast Episode 2: Janine Sickmeyer</h6>
              <p>Remote Choice | Aug 31, 2021</p>
              <a href='https://podcasts.apple.com/us/podcast/episode-2-janine-sickmeyer/id1583666953?i=1000533766304' target="_blank" rel="noreferrer"> 
                <Image src={ArrowRight} />
              </a>
            </div>
            <div className="news-box">
              <h6>Entrepreneurs raising $50M Overlooked Ventures VC fund for, well, the overlooked</h6>
              <p>Columbus Business First | May 19, 2021</p>
              <a href='https://www.bizjournals.com/columbus/news/2021/05/19/columbus-sc-entrepreneurs-raising-50m-vc-fund.html' target="_blank" rel="noreferrer"> 
                <Image src={ArrowRight} />
              </a>
            </div>
          </div>
        </Row>
      </Element>
    </div>
  );
};

export default News;
